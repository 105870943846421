<template>
  <div class="unOuter mainOuter">
    <div class="container_un animated fadeInLeft ani_delay05">
      <div class="subWhite">
        <div class="sub_bg_bot"></div>
        <div class="subTitBox">
          <p id="t_page_title" class="subTitle">{{$t('changePw.title')}}</p>
        </div>
        <div class="RightBox">
          <div class="nTab">
            <ul class="tabs d-flex gap-3">
              <li class="w02 borL">
                <router-link to="/profile" id="t_tab01">{{$t('changePw.profile')}}</router-link>
              </li>
              <li class="w02 on text-white">
                <router-link to="#" id="t_tab02">{{$t('changePw.title')}}</router-link>
              </li>
            </ul>
            <div class="clearfix"></div>
            <section>
              <div class="bTable" style="width: 100%;">
                <div novalidate="" target="process" method="post" name=""
                     class="form-horizontal ng-untouched ng-pristine ng-valid">
                  <input type="hidden" name="hidden_pass" id="hidden_pass">
                  <p id="t_current_pwd" class="th">{{$t('changePw.curPw')}}</p>
                  <p class="td input">
                    <input type="password" v-model="old_password" id="oldpass"
                           ng-reflect-name="oldpass" class="ng-untouched ng-pristine ng-valid">
                  </p>
                  <p id="t_new_pwd" class="th">{{$t('changePw.newPw')}}</p>
                  <p class="td input">
                    <input type="password" v-model="password"
                           class="ng-untouched ng-pristine ng-valid">
                  </p>
                  <p id="t_confirm_pwd" class="th">{{$t('changePw.confirmPw')}}</p>
                  <p class="td input">
                    <input type="password" v-model="password_confirm"
                           class="ng-untouched ng-pristine ng-valid">
                  </p>
                  <div class="bWrap">
                    <button class="bOk text-white me-3" @click="changePassword()">{{$t('common.save')}}</button>
                    <a id="t_modal_txt08" class="bCa">{{$t('common.cancel')}}</a>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>

  </div>
</template>

<script>

const CryptoJS = require("crypto-js");

export default {
  data() {
    return {
      old_password: '',
      password: '',
      password_confirm: '',
    }
  },
  methods: {
    changePassword() {
      const old_password = this.old_password;
      const password = this.password;
      const password_confirm = this.password_confirm;

      if (old_password == '') {
        this.$alert(`${this.$i18n.t('changePw.alertCurPw')}`)
        return false;
      }

      if (password == '') {
        this.$alert(`${this.$i18n.t('changePw.alertNewPw')}`)
        return false;
      }
      if (password != password_confirm) {
        this.$alert(`${this.$i18n.t('changePw.alertConfirmPw')}`)
        return false;
      }
      const body = {old_password, password};

      const req = CryptoJS.AES.encrypt(JSON.stringify(body),
          process.env.VUE_APP_SECRET_TOKEN).toString();

      this.$axios.post('/member/profile/ChangePassword', {req}).then(
          res => {
            if (res.status == 200) {
              if (res.data.code == '200') {
                this.$alert(`${this.$i18n.t('changePw.complete')}`).then(
                    () => {
                      this.$store.dispatch('SETLOGOUT').then(
                          () => {
                            window.location.href = "/sign/login";
                          }
                      )
                    }
                )
              } else if (res.data.code == '100') {
                this.$alert(`${this.$i18n.t('changePw.alertConfirmCurPw')}`)
              } else if (res.data.code == '9999') {
                this.$store.dispatch('SETLOGOUT').then(
                    () => {
                      window.location.href = "/sign/login";
                    }
                )
              }
            }
          }
      )
    },
  }
}
</script>

<style lang="scss" scoped>
.nTab .tabs li, .nTab2 .sTab li {
  padding: 0;
}

.nTab .tabs li a, .nTab2 .sTab li a {
  padding: 15px 0;
  display: block;
}
</style>
