<template>
  <div class="unOuter mainOuter">
    <div class="container_un animated fadeInLeft ani_delay05">
      <div class="subWhite">
        <div class="sub_bg_bot"></div>
        <div class="subTitBox">
          <p id="t_page_title" class="subTitle">{{$t('profile.title')}}</p>
        </div>
        <div class="RightBox">
          <div class="position-relative d-inline-block">
            <img class="prof-img ng-star-inserted" :src="profile_image" width="100" alt="profile">
            <div class="form-group">
              <label for="file" class="edit_icon ng-star-inserted text-white">
                <i class="fas fa-pencil"></i>
              </label>
              <div class="d-none">
                <input type="file" id="file" class="hidden" @change="uploadImg">
              </div>
            </div>
          </div>
          <div class="nTab">
            <ul class="tabs d-flex gap-3">
              <li class="w02 on">
                <router-link to="#" id="t_tab01" class="text-white">{{$t('profile.title')}}</router-link>
              </li>
              <li class="w02">
                <router-link to="/profile/chg-pw" id="t_tab02">{{$t('profile.changePw')}}</router-link>
              </li>
            </ul>
            <div class="clearfix"></div>
            <section>
              <div class="form-horizontal ng-untouched ng-pristine ng-valid">
                <input type="hidden" name="gubun" value="1">
                <input type="hidden" name="center" id="center" value="100">
                <div class="bTable4">
                  <div class="w50L">
                    <div id="t_date" class="th">{{$t('profile.joinDate')}}</div>
                    <div class="td basic">
                      <span id="disp_d_accept">{{ join_date }}</span>
                    </div>
                  </div>
                  <div class="w50R">
                    <div id="t_id" class="th">{{$t('profile.id')}}</div>
                    <div class="td basic">
                      <span id="disp_d_uid">{{ id }}</span>
                    </div>
                  </div>
                  <div class="clearfix"></div>
                  <div class="w50L">
                    <div id="t_country" class="th">{{$t('profile.subscribe')}}</div>
                    <div class="td basic">
                      <span id="disp_grp_cd" class="ng-star-inserted">
                          <span class="ng-star-inserted">{{ plan === 0 ? $t('common.nonSubscribe') : 'PLAN' + plan }}</span>
                      </span>
                    </div>
                  </div>
                  <div class="w50R">
                    <div id="t_name" class="th">{{$t('profile.name')}}</div>
                    <div class="td input">
                      <input type="text" name="d_name_h" v-model="name" id="d_name_h"
                             class="ng-untouched ng-pristine ng-valid">
                    </div>
                  </div>
                  <div class="clearfix"></div>
                  <div id="t_hp" class="th">{{$t('profile.nation')}}</div>
                  <div class="td input">
                    <input type="text" readonly="readonly" name="country" v-model="country" id="country"
                           class="ng-untouched ng-pristine ng-valid">
                  </div>
                  <div id="t_email" class="th">{{$t('profile.email')}}</div>
                  <div class="td input">
                    <input type="text" name="email" id="email" readonly="readonly" v-model="email"
                           class="ng-untouched ng-pristine ng-valid">
                  </div>
                  <div id="t_phone" class="th">{{$t('profile.mobile')}}</div>
                  <div class="td input">
                      <input type="text" name="mobile" id="mobile" v-model="mobile"
                             class="ng-untouched ng-pristine ng-valid">
                  </div>
                  <div class="bWrap">
                      <button class="bOk text-white me-3" @click="updateProfile()">{{$t('common.change')}}</button>
                      <a id="t_modal_txt08" class="bCa">{{$t('common.cancel')}}</a>
                  </div>
                </div>
              </div>
            </section>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const CryptoJS = require("crypto-js");
import AWS from 'aws-sdk';

export default {
  data() {
    return {
      region: process.env.VUE_APP_AWSBUCKETREGION,
      IdentityPoolId: process.env.VUE_APP_AWSPOOLID,
      AWSURL: process.env.VUE_APP_AWSURL,
      Bucket: process.env.VUE_APP_AWSBUCKETNAME,
      join_date: '',
      id: '',
      plan: '',
      name: '',
      country: '',
      email: '',
      mobile: '',
      profile_image: '',
      default_image: '',
    }
  },
  mounted() {
    this.getProfile()
  },
  methods: {
    uploadImg(e) {
      if (e.target.files.length === 0) {
        this.profile_image = this.default_image;
        this.changeProfileImage();
      } else {
        const file = e.target.files[0];
        const date = new Date();
        const timestamp = date.getTime();
        const fileName = timestamp + file.name;
        AWS.config.update({
          region: this.region,
          credentials: new AWS.CognitoIdentityCredentials({IdentityPoolId: this.IdentityPoolId})
        });
        const s3 = new AWS.S3({apiVersion: "2006-03-01", params: {Bucket: this.Bucket}});
        s3.upload({Key: fileName, Body: file, ACL: 'public-read', 'ContentType': `${file.type}`},
            (err) => {
              if (err == null) {
                this.profile_image = this.AWSURL + fileName;
                this.CHANGE_PROFILE_IMAGE();
              } else {

              }
            })
      }

    },
    changeProfileImage() {
      const image = this.profile_image;
      const body = {image};

      const req = CryptoJS.AES.encrypt(JSON.stringify(body),
          process.env.VUE_APP_SECRET_TOKEN).toString();

      this.$axios.post('/member/profile/ChangeProfileImage', {req}).then(
          res => {
            if (res.status === 200) {
              if (res.data.code === 200) {
                this.default_image = this.profile_image;
              } else if (res.data.code === 9999) {
                this.$store.dispatch('SETLOGOUT').then(
                    () => {
                      window.location.href = "/sign/login";
                    }
                )
              }
            }
          }
      )
    },
    getProfile() {
      this.$axios.post('/member/profile/GetProfile', {}).then(
          res => {
            if (res.status === 200) {
              if (res.data.code === 200) {
                const e_body = res.data.body;
                const bytes = CryptoJS.AES.decrypt(e_body, process.env.VUE_APP_SECRET_TOKEN);
                const d_res = bytes.toString(CryptoJS.enc.Utf8);
                const body = JSON.parse(d_res)
                this.id = body.id;
                this.email = body.email;
                this.name = body.name;
                this.mobile = body.mobile;
                this.country = body.country;
                this.plan = body.plan;
                this.join_date = body.join_date;
                this.profile_image = body.profile_image;
                this.default_image = body.profile_image;

              } else if (res.data.code === 9999) {
                this.$store.dispatch('SETLOGOUT').then(
                    () => {
                      window.location.href = "/sign/login";
                    }
                )
              }
            }
          }
      )
    },
    async updateProfile() {
      if (this.name === '' || this.name === undefined) {
        await this.$alert(this.$i18n.t('profile.alertName'));
        return false;
      }

      if (this.mobile === '' || this.mobile === undefined) {
        await this.$alert(this.$i18n.t('profile.alertMobile'));
        return false;
      }

      const body = {name: this.name, mobile: this.mobile};

      const req = CryptoJS.AES.encrypt(JSON.stringify(body),
          process.env.VUE_APP_SECRET_TOKEN).toString();

      try {
        const res = await this.$axios.post('/member/profile/update', {req});

        if (res.data.code === 200) {
          await this.$alert(`${this.$i18n.t('profile.complete')}`);
        } else {
          await this.$alert(`${this.$i18n.t('profile.alertRetry')}[${res.data.code}]`);
        }
      } catch (e) {
        await this.$alert(`${this.$i18n.t('profile.alertRetry')}[${e.response.status}]`);
      }
    }
  }
}
</script>

<style lang="scss" scoped>

.td.input {
  background-color: rgba(255, 255, 255, 0.2);
}

.prof-img {
  width: 100px;
  height: 100px;
  border: 1px solid #999;
  border-radius: 50%;
  margin-bottom: 10px;
}

.td.basic {
  line-height: 3.2;
}

.edit_icon {
  position: absolute;
  bottom: 0;
  right: 0;
  border-radius: 100%;
  background-color: rgb(77, 78, 135);
  width: 32px;
  height: 32px;
  line-height: 32px;
  text-align: center;;
  cursor: pointer;
}

.nTab .tabs li, .nTab2 .sTab li {
  padding: 0;
}

.nTab .tabs li a, .nTab2 .sTab li a {
  padding: 15px 0;
  display: block;
}
</style>
